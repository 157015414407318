import "./age-verify";
import "./scrollspy";

// GA
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'G-SDKRP5F8LM');
// GA

// Navigation
const elm1 = document.getElementById("in-store.html");
const elm2 = document.getElementById("delivery.html");
const elm3 = document.getElementById("daily-deals.html");

switch (window.location.pathname) {
    case "/index.html":
        if (elm1) { elm1.style.opacity = "100"; }
        if (elm2) { elm2.style.opacity = "100"; }
        if (elm3) { elm3.style.opacity = "100"; }
        break;
    case "/in-store.html":
        if (elm1) { elm1.style.textDecoration = "underline"; }
        if (elm1) { elm1.style.color = "var(--color-primary)"; }
        if (elm1) { elm1.style.fontWeight = "700"; }
        if (elm1) { elm1.style.opacity = "100"; }
        break;
    case "/delivery-deals.html":
        if (elm2) { elm2.style.textDecoration = "underline"; }
        if (elm2) { elm2.style.color = "var(--color-primary)"; }
        if (elm2) { elm2.style.fontWeight = "700"; }
        if (elm2) { elm2.style.opacity = "100"; }
        break;
    case "/daily-deals.html":
        if (elm3) { elm3.style.textDecoration = "underline"; }
        if (elm3) { elm3.style.color = "var(--color-primary)"; }
        if (elm3) { elm3.style.fontWeight = "700"; }
        if (elm3) { elm3.style.opacity = "100"; }
        break;
}
// Navigation

// Header slider
document.addEventListener("DOMContentLoaded", function (arg) {
    var splide = new Splide('.splide', {
        focus: 'center',
        rewind: true,
        arrows: true
    });
    splide.mount();

    var review_splide = new Splide('.review_splide', {
        classes: {
            pagination: 'splide__pagination review-images-pagination',
            page: 'splide__pagination__page review-images-pagination-page',
        },
        type: 'loop',
        drag: 'free',
        autoScroll: {
            speed: 1,
        },
        rewind: true,
        arrows: true,
        perPage: 3,
        perMove: 1,
        // height     : '20rem',
        autoWidth: true,
        autoHeight: true,
        focus: 'center',
        breakpoints: {
            600: {
                perPage: 2,
                // height : '20rem',
                autoHeight: true,

                autoWidth: true
            },
        }
    });
    review_splide.mount(window.splide.Extensions);
});
// Header slider
