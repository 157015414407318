document.addEventListener("DOMContentLoaded", function (arg) {
    let lastScrollTop = 0; // This Varibale will store the top position

    const navbar = document.getElementById('navbar'); // Get The NavBar

    window.addEventListener('scroll', function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const scrollDirection = lastScrollTop > scrollTop === true ? "top" : lastScrollTop < scrollTop === true ? "bottom" : "none";
        switch (scrollDirection) {
            case "top":
                navbar.style.top = '0px'
                break;
            case "bottom":
                navbar.style.top = '-105px'
                break;
            case "none":
                break;
        }

        lastScrollTop = scrollTop; //New Position Stored
    });
});
