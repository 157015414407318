// Age verify
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Age verify
document.addEventListener("DOMContentLoaded", function (arg) {
    const cookie = getCookie("is_legal");
    document.getElementById("allow-age").addEventListener("click", () => {
        document.body.classList.remove("overflow-hidden");
        document.getElementById("age-verify").classList.add("hidden")
        setCookie("is_legal", true, 30)
    })

    document.getElementById("disallow-age").addEventListener("click", () => {
        document.location.href = "https://www.google.com/"

    })
    console.log(cookie)
    if (cookie) {
        document.body.classList.remove("overflow-hidden");
        document.getElementById("age-verify").classList.add("hidden")
    } else {
        document.body.classList.add("overflow-hidden");
        document.getElementById("age-verify").classList.add("opacity-100")
    }
});

